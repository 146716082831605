import React from 'react'
import "./createPost.css";
import { useTranslation } from 'react-i18next';
import Buttons from './Buttons';



const SectionOne = ({ data, inputChange, nextStep, prevStep, citiesData, validator }) => {
    const { t } = useTranslation();


    return (
        <div className="createPost__container">
            <form onSubmit={nextStep} className="createPost__form">
                <div className="createPost__input">
                    <select
                        className="register__select"
                        labelid="city-select"
                        id="citySelect"
                        label={t("select city")}
                        name="city"
                        onChange={inputChange}
                        value={data.city}
                    >
                        {
                            !citiesData ? (
                                <option value="for rent">{t("no cities")}</option>
                            ) : (
                                <option value="for rent">{t("select city")}</option>
                            )
                        }

                        {
                            citiesData?.map((option, index) => {
                                return <option key={index} value={option} style={{ color: "black" }}>{option}</option>
                            })
                        }
                    </select>

                    <label className="register__error text-danger">
                        {validator.message("city", data.city, "required|city", {
                            messages: {
                                required: t("required"),
                            },
                        })}
                    </label>
                </div>


                <div className="createPost__input">
                    <input
                        autocomplete="on"
                        type="text"
                        placeholder={t("quarter")}
                        name="neighborhood"
                        value={data.neighborhood}
                        onChange={inputChange}
                        className=""
                    />
                    <label className="register__error text-danger">
                        {validator.message(
                            "neighborhood",
                            data.neighborhood,
                            "required|neighborhood",
                            {
                                messages: {
                                    required: t("required"),
                                },
                            }
                        )}
                    </label>
                </div>
                <div className="createPost__input">
                    <input
                        autocomplete="on"
                        type="text"
                        placeholder={t("no of bedrooms")}
                        name="bedrooms"
                        value={data.bedrooms}
                        onChange={inputChange}
                        className=""
                    />
                    <label className="register__error text-danger">
                        {validator.message(
                            "bedrooms",
                            data.bedrooms,
                            "required|bedrooms",
                            {
                                messages: {
                                    required: t("required"),
                                },
                            }
                        )}
                    </label>
                </div>
                <div className="createPost__input">
                    <input
                        autocomplete="on"
                        type="text"
                        placeholder={t("bathrooms")}
                        name="numberOfToilets"
                        value={data.numberOfToilets}
                        onChange={inputChange}
                        className=""
                    />
                    <label className="register__error text-danger">
                        {validator.message(
                            "numberOfToilets",
                            data.numberOfToilets,
                            "required|numberOfToilets",
                            {
                                messages: {
                                    required: t("required"),
                                },
                            }
                        )}
                    </label>
                </div>
                
                <Buttons previous={prevStep} next={nextStep} />
            </form>
        </div>
    )
}

export default SectionOne