// eslint-disable-next-line
import React, { useState, Suspense, useEffect, useCallback,useContext } from "react";
import '@tomtom-international/web-sdk-maps/dist/maps.css'

import {
  Loader, Navbar, Footer, ProtectedRoute, Search,
  //NoPropertyFallback 
} from "./components";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { IoIosArrowDropup } from "react-icons/io";
import useScrollPosition from "@react-hook/window-scroll";
import { useDispatch, useSelector } from "react-redux";
import { getAllPosts, getSubscriptionExpiryDataAction, searchAction, unSubscriptionAction,getCurrentLocationCoordsAction, deleteOverduePostAction } from "./redux/actions";
import { useNavigate } from 'react-router-dom';
import {
  dateConverter,
  getCurrentCoordinatesAndLocation, today
  // ,testBoundingBox 
} from "./utils/utils";
//import { useGeolocated } from "react-geolocated";
import { navLinks } from "./utils/navUtils";
// import { googleMapApiPrompt } from "./utils/utils";

import { IPInfoContext } from 'ip-info-react';



// const onError = (error) => {
//   console.log(error);
// }

function App() {
  const { filteredPosts, forRent, posts, subscriptionExpiryDate, refreshedUser } = useSelector((state) => state.posts)
  // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  //   useGeolocated({
  //     positionOptions: {
  //       enableHighAccuracy: true,
  //     },
  //     userDecisionTimeout: 10000,
  //     watchLocationPermissionChange: true,
  //     watchPosition: false,
  //     onError
  //   });
  const { user } = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const [displayScroll, setDisplayScroll] = useState(false);
  const scrollY = useScrollPosition(60 /*frames per second*/);
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResponse, setSearchResponse] = useState("")
  const [currentLocation, setCurrentLocation] = useState({})
  const [filteredApartments, setFilteredApartments] = useState([])
  const [currentPage, setCurrentPage] = useState(window.location.pathname)
  const { id, displayName } = user || {}
  const { isSubscribed } = refreshedUser || {}
  const navigate = useNavigate()
  const userInfo = useContext(IPInfoContext);
  
  //console.log(userInfo)
  



useEffect(() => {
  if(posts?.length > 0){
    const postedDate = "2 months ago"
  posts?.forEach((post,index) => {
    if(dateConverter(post?.post?.timestamp) === postedDate){
      console.log("deleted",post?.id,index)
      dispatch(deleteOverduePostAction(post?.id))
    }
  })
  }
}, [posts,dispatch])

  useEffect(() => {
    dispatch(getAllPosts());

  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getSubscriptionExpiryDataAction(id))
    }
    dispatch(getAllPosts())
  }, [dispatch, id])
  //  console.log(isSubscribed)
  //  console.log(subscriptionExpiryDate)
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };


  useEffect(() => {
    if (!displayName) return
    if ((isSubscribed === true) && (today >= subscriptionExpiryDate)) {
      dispatch(unSubscriptionAction(id, isSubscribed, navigate))
    }

  }, [dispatch, subscriptionExpiryDate, id, isSubscribed, navigate, displayName]);



  useEffect(() => {
    if (scrollY >= 1530) {
      setDisplayScroll(true);
    } else {
      setDisplayScroll(false);
    }
  }, [scrollY]);

  useEffect(() => {
    if (currentPage !== "/") {
      setCurrentPage(window.location.pathname)
    }


  }, [currentPage])



  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();

      if (window.location.href !== "/") {
        navigate('/')
      }

      dispatch(searchAction(posts, searchTerm, today, subscriptionExpiryDate, navigate))

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, dispatch, posts, searchTerm, subscriptionExpiryDate])


  useEffect(() => {
    if (!searchTerm) {
    if(displayName && isSubscribed){
      setFilteredApartments(forRent)
    }else{
      setFilteredApartments(forRent?.slice(0, 25))
    }
      // setFilteredApartments(forRent?.slice(0, 9))
    } else {
      setFilteredApartments(filteredPosts)
    }
    // eslint-disable-next-line
  }, [searchTerm, filteredPosts, forRent,isSubscribed,displayName]);

  useEffect(() => {
    const startingCoords2 = [userInfo?.longitude,userInfo?.latitude]

      const getData = async () => {
        const data = await getCurrentCoordinatesAndLocation(userInfo?.latitude,userInfo?.longitude)
        const startingNeighborhood = data[5]
        dispatch(getCurrentLocationCoordsAction(startingCoords2,startingNeighborhood))
        const [city, boundingBox, suburb] = data.slice(3, 6)
        setCurrentLocation({ city, suburb })
        console.log(boundingBox)
        // console.log(data)
       

      }
      getData()
    
  }, [dispatch,userInfo?.longitude,userInfo?.latitude])

  // if(!filteredPosts){
  //   return (
  //     <NoPropertyFallback/>
  //   )
  // }

  return (
    <Suspense fallback={<Loader />}>
      <div className="App">
        <Navbar />
        {
          (currentPage === "/" || currentPage === "/for-sale") && (
            <div className="search__container">
              <Search
                searchProperty={handleSearch}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                message={searchResponse}
                setSearchResponse={setSearchResponse}
              />

            </div>
          )}
        <Routes>

          {/* <Route path="/for-sale" element={<ForSaleScreen posts={forSale} />} />
          <Route path="/guest-house" element={<GuestHouse />} />
          <Route path="/post/:id" element={<PostDetails />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/subscribe" element={<Subscription />} /> */}

          {
            navLinks?.slice(0, 7)?.map((item) => (
              <Route path={item.link} key={item.link} element={<item.element posts={filteredApartments} currentLocation={currentLocation} />} />
            ))
          }
          {/* protected routes */}
          <Route element={<ProtectedRoute />}>
            {
              navLinks?.slice(8, 13)?.map((item) => (
                <Route path={item.link} key={item.link} element={<item.element posts={posts} id={id} />} />
              ))
            }
            {/* <Route path="/my-posts/:id" element={<MyPosts posts={posts} id={id} />} />
            <Route path="/profile/:name" element={<Profile />} />
            <Route path="/favorites/:id" element={<Favorites />} />
            <Route path="/favorite/:id" element={<Favorite />} />
            <Route path="/create-post/:id" element={<CreatePost locationAndCoords={locationData} />} />
            <Route path="/edit-post/:id" element={<EditPost />} />
            <Route path="/dashboard/:id" element={<Dashboard />} /> */}
          </Route>
        </Routes>
        <a href="#top">
          {displayScroll && (
            <IoIosArrowDropup
              size={30}
              color="#4db5ff"
              className="app__topIcon"
              onClick={scrollToTop}
            />
          )}
        </a>
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
