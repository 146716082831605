import * as actionType from './constants'



export const postReducer = (state = {isLoading: null, posts: [],totalRentalProperties:0, filteredPosts:[],forSale:[],forRent:[],favorites:[],isSearchLoading:false,savedBy:[],subscriptionExpiryDateId:null,refreshedUser:{},subscriptionExpiryDate:null,savedToFavorites:null, startingCoords:null},action) => {
    switch (action.type) {
        case actionType.START_LOADING:
            return {...state, isLoading: true}
        case actionType.START_LOADING_SEARCH:
            return {...state, isSearchLoading: true}
            case actionType.END_LOADING:
              return {...state, isLoading:false}
        case actionType.GET_ALL_POSTS: 
        const totalNumber = action.payload?.length

        return { ...state, 
            posts:action.payload,totalRentalProperties:totalNumber
           }
        case actionType.GET_ALL_RENTS_POSTS: 
        return { ...state, 
            forRent:action.payload,
           }
        case actionType.GET_ALL_SALE_POSTS: 
        return { ...state, 
            forSale:action.payload,
           }
        case actionType.REFRESH_USER: 
        return { ...state, 
            refreshedUser:action.payload,
           }
        case actionType.GET_FAVORITES: 
        return { ...state, 
            favorites:action.payload,
           }
           case actionType.GET_SAVED_BY: 
           return { ...state, 
               savedBy:action.payload,
              }
           case actionType.SAVED_TO_FAVORITES: 
           return { ...state, 
               savedToFavorites:true,
              }
           case actionType.REMOVE_SAVED_BY: 
           return { ...state, 
               savedBy:null, savedToFavorites:false,
              }
        case actionType.DELETE_POST: 
    
        return { ...state, 
            posts: action.payload,
           }
      case actionType.SET_SEARCH_TERM:
                return { ...state, filteredPosts:action.payload}
                case actionType.SUBSCRIBE_USER:
                return { ...state, isUserSubscribed:true}
      case actionType.UNSUBSCRIBE_USER:
                return { ...state, isUserSubscribed:false}
      case actionType.GET_SUBSCRIPTION_EXPIRY_DATE:
                return { ...state, subscriptionExpiryDate:action.payload}
      case actionType.GET_SUBSCRIPTION_EXPIRY_DATE_ID:
                return { ...state, subscriptionExpiryDateId:action.payload}
      case actionType.SET_CURRENT_COORDS:
                return { ...state, startingCoords:action.payload}
        default:
            return state
    }
}



    
