import React, { useState, useEffect } from "react";
import { useLocation,Link, useParams } from "react-router-dom";
import "./postDetails.css";
import ReactWhatsapp from "react-whatsapp";
import { loader } from "../../Assets";
import { useTranslation } from 'react-i18next';
import { RiWhatsappLine } from "react-icons/ri";
import { Carousel } from 'react-responsive-carousel';
import { dateConverter, getCoordinates } from "../../utils/utils";
import { useSelector,useDispatch } from 'react-redux'
import {
  //VideoPlayer
  //Map
  //MapContainer
} from "../../components";
import { getUsersWhoSavedToFavoritesAction, toggleSaveFavoritesAction } from "../../redux/actions";
import { ToastContainer, 
  toast
 } from "react-toastify";

const PostDetails = () => {
  const { user } = useSelector((state) => state?.user)
  const savedBy  = JSON.parse(localStorage.getItem("saver"))
  const [isSavedToFavorites, setIsSavedToFavorites] = useState(false)
  const { role,displayName } = user || {};
  const dispatch = useDispatch()
  const { t } = useTranslation();
   const currentUserId = user?.id;
  const { state } = useLocation();
  const { refreshedUser } = useSelector((state) => state.posts)
  const { isSubscribed } = refreshedUser || {}
  const [number, setNumber] = useState("237677983166");
  const [mapLongitude, setMapLongitude] = useState(-0.09);
  const [mapLatitude, setMapLatitude] = useState(51.505);
  const [position, setPosition] = useState([+mapLatitude, +mapLongitude]);
  const params = useParams()

  const {
    
    country,
    city,
    neighborhood,
    price,
    type,
    category,
    currency,
    numberOfToilets,
    numberAvailable,
    bedrooms,
    dining,
    parking,
    phoneNumber,
    //postedBy,
    imageUrls,
    timestamp,
    fenced,
    oneBalcony,
    twoBalconies,
    securityGuard,
    surveillanceCamera,
    wardrobe,
    electricity,
    water,
    airConditioner,
    camWater,
    generator,
    waterHeater,
    interphone,
    likes
    // videoUrl

  } = state?.post || {};
  const finalDate = dateConverter(timestamp)



  useEffect(() => {
    const getData = async () => {
      const data = await getCoordinates(neighborhood, city)
      console.log(position)
      setMapLatitude(parseFloat(data?.lat))
      setMapLongitude(parseFloat(data?.lon))
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPosition((prevState) => [+mapLatitude, +mapLongitude]);
  }, [mapLatitude, mapLongitude]);

  useEffect(() => {
    if (phoneNumber !== null) {
      console.log(phoneNumber)
      setNumber(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if(currentUserId){
      savedBy?.forEach((item) => {
        if(item?.saver?.match(currentUserId) && item?.post?.match(params.id)){
          setIsSavedToFavorites(true)
        }
      })
    }
    },[savedBy,currentUserId,params.id,likes])


    useEffect(() => {
      if(currentUserId){
       dispatch(getUsersWhoSavedToFavoritesAction(params.id,currentUserId))
       
      }
     
       // eslint-disable-next-line
     },[dispatch,currentUserId,params.id])



     const toggleSave = async () => {
      if(!displayName)return
      if(!isSavedToFavorites){
        dispatch(toggleSaveFavoritesAction(state?.post, params.id, currentUserId))
        toast.info(t("saved"), {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
      }
  return (
    <div className="post__details">
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="details__heading">
        <h2>{t(type)}</h2>
      </div>
      <div className="postDetails__container d-flex justify-content-center align-items-center gap-2 flex-wrap">

        <div className="post__fullInfo">
          <h5 className="postDetails__emphasis">
            {neighborhood}
          </h5>
          <h5 className="postDetails__emphasis">
            {t("no of bedrooms")}{" "}{bedrooms}
          </h5>
          <h5 className="postDetails__emphasis">
            {t("bathrooms")}{" "}{numberOfToilets}
          </h5>
          {
            camWater && (
              <h5 className="postDetails__subtitle">
            {t(camWater)}  ✅
          </h5>
            )
          }
          {
            interphone && (
              <h5 className="postDetails__subtitle">
            {t(interphone)}  ✅
          </h5>
            )
          }
          {
            generator && (
              <h5 className="postDetails__subtitle">
            {t(generator)}  ✅
          </h5>
            )
          }
          {
            waterHeater && (
              <h5 className="postDetails__subtitle">
            {t(waterHeater)}  ✅
          </h5>
            )
          }
          {
            dining && (
              <h5 className="postDetails__subtitle">
            {t(dining)}  ✅
          </h5>
            )
          }
          {
            airConditioner && (
              <h5 className="postDetails__subtitle">
            {t(airConditioner)}  ✅
          </h5>
            )
          }
          {
            water && (
              <h5 className="postDetails__subtitle">
            {t(water)}  ✅
          </h5>
            )
          }
          {
            electricity && (
              <h5 className="postDetails__subtitle">
            {t(electricity)}  ✅
          </h5>
            )
          }
          {
            wardrobe && (
              <h5 className="postDetails__subtitle">
            {t(wardrobe)}  ✅
          </h5>
            )
          }
          {
            surveillanceCamera && (
              <h5 className="postDetails__subtitle">
            {t(surveillanceCamera)}  ✅
          </h5>
            )
          }
          {
            securityGuard && (
              <h5 className="postDetails__subtitle">
            {t(securityGuard)}  ✅
          </h5>
            )
          }
          {
            twoBalconies && (
              <h5 className="postDetails__subtitle">
            {t(twoBalconies)}  ✅
          </h5>
            )
          }
          {
            oneBalcony && (
              <h5 className="postDetails__subtitle">
            {t(oneBalcony)}  ✅
          </h5>
            )
          }
          {
            fenced && (
              <h5 className="postDetails__subtitle">
            {t(fenced)}  ✅
          </h5>
            )
          }
          {
            parking && (
              <h5 className="postDetails__subtitle">
            {t(parking)}  ✅
          </h5>
            )
          }
          {
            category === t("for rent") && (
              <>
                <h5 className="postDetails__subtitle">
                 {t(numberAvailable)}{" "} {t("available")}
                </h5>
              </>
            )
          }
          <h5 className="postDetails__emphasis">{price}{" "}
            {currency} {
              t(category.toLowerCase()) === t("for rent") && t(type) !== t("guest house") ? t("per month") : t(category.toLowerCase()) === t("for rent") && t(type) === t("guest house") ? t("per night") : ''
            }
          </h5>
          {
            isSubscribed === true ? (
              <>
                <h5 className="postDetails__emphasis">
                  {phoneNumber}{" "}
                </h5>
                <h5 className="postDetails__subtitle">
                  <ReactWhatsapp
                    number={number}
                    message={` ${t("whatsapp message")} https://homscraper.com/post/${params?.id}`}
                  >
                    <div className="whatsappPost__button">
                      <RiWhatsappLine
                        color="#06e651"
                        size={15}
                      />{" "}
                      <h5 className="subtitle"> click to send WhatsApp message</h5>
                      {/* <h5 className="subtitle"> click to message {postedBy}</h5> */}
                    </div>
                  </ReactWhatsapp>{" "}
                </h5>
              </>
            ) : (
              <Link to="/subscribe" className="postDetails__subtitle2">
              {t("see contact")}
              </Link>
            )
          }

          <h5 className="postDetails__subtitle">
            {country}{" "}
          </h5>
          <h5 className="postDetails__subtitle">
            {city}{" "}
          </h5>
          <h5 className="postDetails__subtitle">
            {finalDate}{" "}
          </h5>
          {/* <h5 className="postDetails__subtitle">
            {t("posted by")} {" "} {postedBy}{" "}
          </h5> */}
          {
              (role && role === "client") && (
                <div className="post__save">
                   <button className={ isSavedToFavorites ? "saved__button" : "save__button"} 
                   onClick={toggleSave}>{t("save property")}</button>
                </div>
               )
            } 
          
        </div>
        <div className="carousel-container">
          {imageUrls?.length === 0 ? (
            <img src={loader} alt="" className="loading__image" key={imageUrls[0]} />
          ) : (
            <Carousel
              className='carousel'
              axis='horizontal'
              dynamicHeight={true}
              showArrows={true}
              showIndicators={false}
              showThumbs={false}
              autoPlay={false}
              infiniteLoop={false}
              showStatus={false}
            >
              {
                imageUrls?.map((image, index) => (
                  <div className="imageSlider__images" key={index}>
                    <img src={image} alt='details slider' loading="lazy" />
                  </div>
                ))
              }
            </Carousel>
          )}
        </div>
      </div>
      {/* <div className="post__details-video">
      {
        videoUrl && (
          <VideoPlayer videoSrc={videoUrl}/>
        )
       } 
      </div>  */}
      {/* {
            isSubscribed === true ? (
      <div className="post__map">
        <Map item={neighborhood} position={position} lon={mapLongitude} lat={mapLatitude} destination={[mapLongitude,mapLatitude]}/>
      </div>
        ) : (
        <Link to="/subscribe" className="postDetails__subtitle2">
        {t("see map")}
        </Link>
      )
    }  */}
    </div>
  );
};

export default PostDetails;
