import React from 'react'
import "./createPost.css";
import { useTranslation } from 'react-i18next';

const Buttons = ({previous,next}) => {
    const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between align-items-center">
        {
            previous && (
                <button className="next" onClick={previous}>{t("previous button")}</button>
            )
        }
        <button className="previous" onClick={next}>{t("next button")}</button>
        </div>
  )
}

export default Buttons