import {
  CreatePost,
  Dashboard,
  // EditForSale,
  EditPost,
  ForSaleScreen,
  HomeScreen,
  Login,
  PostDetails,
  Profile,
  Register,
  VerifyEmail,
  GuestHouse,
  Favorites,
  Favorite,
  Subscription,
} from "../pages";

export const navLinks = [
  { link: "/", element: HomeScreen },
  { link: "/for-sale", element: ForSaleScreen },
  { link: "/guest-house", element: GuestHouse },
  { link: "/post/:id", element: PostDetails },
  { link: "/register", element: Register },
  { link: "/login", element: Login },
  { link: "/subscribe", element: Subscription },
  { link: "/verify-email", element: VerifyEmail },
  { link: "/profile/:name", element: Profile },
  { link: "/:id/favorites", element: Favorites },
  { link: "/favorite/:id", element: Favorite },
  { link: "/:id/create-post", element: CreatePost },
  { link: "/:id/edit-post/:id", element: EditPost },
  { link: "/:id/dashboard", element: Dashboard },

];
