import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { loader } from "../../../Assets";
//import { postActions } from '../../../store/postSlice/postSlice';
//import './css'
// import {
//   Box,
//   IconButton,
//   LinearProgress,
// } from "@mui/material";
import useValidator from "../../../utils/CustomValidator";
import { auth, db } from "../../../firebaseConfig";
import { updateDoc, doc, serverTimestamp } from "firebase/firestore";
import PhoneInput from "react-phone-input-2";
import "./createPost.css";
import { useTranslation } from 'react-i18next';



const EditPost = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validator, showValidationMessage] = useValidator();
  const [error, setError] = useState("");
  const { id } = useParams();



  const {
    type,
    country,
    city,
    neighborhood,
    price,
    currency,
    numberOfToilets,
    bedrooms,
    dining,
    parking,
    phoneNumber,
    userId,
    streetNumber,
    countryCode,
    postalCode,
    fenced,
    oneBalcony,
    twoBalconies,
    securityGuard,
    surveillanceCamera,
    veranda,
    wardrobe,
    electricity,
    water,
    airConditioner,
    camWater,
generator,
waterHeater,
interphone,
  } = state?.post;
  const [updateData, setUpdateData] = useState({
    type: type,
    city: city,
    neighborhood: neighborhood,
    streetNumber: streetNumber,
    countryCode: countryCode,
    postalCode: postalCode,
    price: price,
    country: country,
    currency: currency,
    bedrooms: bedrooms,
    numberOfToilets: numberOfToilets,
    dining: dining,
    parking: parking,
    fenced: fenced,
    oneBalcony: oneBalcony,
    twoBalconies: twoBalconies,
    securityGuard: securityGuard,
    surveillanceCamera: surveillanceCamera,
    veranda: veranda,
    wardrobe: wardrobe,
    electricity: electricity,
    water: water,
    airConditioner: airConditioner,
    camWater:camWater,
        generator:generator,
        waterHeater:waterHeater,
        interphone:interphone,
  });
  const [newPhoneNumber, setNewPhoneNumber] = useState(phoneNumber);

  useEffect(() => {
    const result = document.getElementById('phone__input').getElementsByClassName('special-label');
    result[0].style.display = 'none';
  }, []);

  const handlePhoneInputChange = (phone) => {
    setNewPhoneNumber(phone);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setUpdateData({ ...updateData, [e.target.name]: value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const taskDocRef = doc(db, "apartments", id);
    if (auth?.currentUser?.uid === userId && validator.allValid()) {
      try {
        await updateDoc(taskDocRef, {
          timestamp: serverTimestamp(),
          userId: userId,
          type: updateData.type,
          city: updateData.city,
          phoneNumber: newPhoneNumber,
          country: updateData.country,
          neighborhood: updateData.neighborhood,
          price: updateData.price,
          currency: updateData.currency,
          bedrooms: updateData.bedrooms,
          numberOfToilets: updateData.numberOfToilets,
          dining: updateData.dining,
          parking: updateData.parking,
          fenced: updateData.fenced,
          oneBalcony: updateData.oneBalcony,
          twoBalconies: updateData.twoBalconies,
          securityGuard: updateData.securityGuard,
          surveillanceCamera: updateData.surveillanceCamera,
          veranda: updateData.veranda,
          wardrobe: updateData.wardrobe,
          electricity: updateData.electricity,
          water: water,
          airConditioner: updateData.airConditioner
        });
        setLoading(false);
        navigate("/");
      } catch (err) {
        setError(err?.message);
      }
    } else {
      showValidationMessage(true);
      setLoading(false);
    }
  };

  return (
    <div className="register__container">
      <form onSubmit={handleUpdate} className="register__form ">
        <div className="post__header">
          <h2>{t("edit post")}</h2>
        </div>

        <div className="register__input">
          <input
            type="text"
            placeholder={t("price")}
            name="price"
            value={updateData.price}
            onChange={handleInputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message("price", updateData.price, "required|price", {
              messages: {
                required: t("required"),
              },
            })}
          </label>
        </div>

        <div className="register__input">
          <select
            className="register__select"
            labelid="type-select"
            id="typeSelect"
            label="apartmentType"
            name="type"
            onChange={handleInputChange}
            value={updateData.type}
          >
            {/* <option>{t("select type")}</option> */}
            <option value={t(updateData.type)}>{t(updateData.type)}</option>
            <option value="modern studio">{t("modern studio")}</option>
            <option value="modern room">{t("modern room")}</option>
            <option value="simple apartment">{t("simple apartment")}</option>
            <option value="guest house">{t("guest house")}</option>
            <option value="flat">{t("flat")}</option>
          </select>
          <label className="register__error text-danger">
            {validator.message("type", updateData.type, "required|type", {
              messages: {
                required: t("required"),
              },
            })}
          </label>
        </div>
        <div className="register__input">
          <select
            className="register__select"
            labelid="currency-select"
            id="currencySelect"
            label="Currency"
            name="currency"
            onChange={handleInputChange}
            value={updateData.currency}
          >
            <option>{t("select currency")}</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="FCFA">FCFA</option>
          </select>
        </div>

        <div className="register__input">
          <input
            type="text"
            placeholder={t("city")}
            name="city"
            value={updateData.city}
            onChange={handleInputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message("city", updateData.city, "required|city", {
              messages: {
                required: t("required"),
              },
            })}
          </label>
        </div>
        <div className="register__input">
          <input
            type="text"
            placeholder={t("country")}
            name="country"
            value={updateData.country}
            onChange={handleInputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message(
              "country",
              updateData.country,
              "required|country",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="register__input">
          <input
            type="text"
            placeholder={t("quarter")}
            name="neighborhood"
            value={updateData.neighborhood}
            onChange={handleInputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message(
              "neighborhood",
              updateData.neighborhood,
              "required|neighborhood",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        {
          updateData?.type !== "modern room" && (
            <>
              <div className="register__input">
                <input
                  type="text"
                  name="bedrooms"
                  placeholder={t("no of bedrooms")}
                  value={updateData.bedrooms}
                  onChange={handleInputChange}
                  className=""
                />
                <label className="register__error text-danger">
                  {validator.message(
                    "bedrooms",
                    updateData.bedrooms,
                    "required|bedrooms",
                    {
                      messages: {
                        required: t("required"),
                      },
                    }
                  )}
                </label>
              </div>
              <div className="register__input">
                <input
                  type="text"
                  placeholder={t("bathrooms")}
                  name="numberOfToilets"
                  value={updateData.numberOfToilets}
                  onChange={handleInputChange}
                  className=""
                />
                <label className="register__error text-danger">
                  {validator.message(
                    "numberOfToilets",
                    updateData.numberOfToilets,
                    "required|numberOfToilets",
                    {
                      messages: {
                        required: t("required"),
                      },
                    }
                  )}
                </label>
              </div>
              <div className="createPost__input">
                <div className="d-flex flex-wrap w-20 justify-content-evenly align-items-start">
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("water")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="water"
                      value={updateData.water}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("water heater")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="waterHeater"
                                value={updateData.waterHeater}
                                onChange={handleInputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("cam water")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="camWater"
                                value={updateData.camWater}
                                onChange={handleInputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("generator")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="generator"
                                value={updateData.generator}
                                onChange={handleInputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("interphone")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="interphone"
                                value={updateData.interphone}
                                onChange={handleInputChange}
                                className="mx-2"
                            />
                        </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("electricity")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="electricity"
                      value={updateData.electricity}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("wardrobe")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="wardrobe"
                      value={updateData.wardrobe}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("veranda")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="veranda"
                      value={updateData.veranda}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("fenced")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="fenced"
                      value={updateData.fenced}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("dining")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="dining"
                      value={updateData.dining}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="d-flex justify-content-evenly labelWidth align-items-center text-white border">
                    {t("parking")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="parking"
                      value={updateData.parking}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth  text-center d-flex justify-content-evenly align-items-center">
                    {t("air conditioner")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="airConditioner"
                      value={updateData.airConditioner}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("surveillance camera")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="surveillanceCamera"
                      value={updateData.surveillanceCamera}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("security guard")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="securityGuard"
                      value={updateData.securityGuard}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("two balconies")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="twoBalconies"
                      value={updateData.twoBalconies}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                  <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                    {t("one balcony")}
                    <input
                      autocomplete="on"
                      type="checkbox"
                      name="oneBalcony"
                      value={updateData.oneBalcony}
                      onChange={handleInputChange}
                      className="mx-2"
                    />
                  </label>
                </div>
              </div>
            </>
          )}
        <div className="register__input" id="phone__input">
          <PhoneInput
            type="text"
            country={"cmr"}
            enableAreaCodes={true}
            // onlyCountries={['us','de','ro']}
            // areaCodes={{us: ['999']}}

            inputProps={{
              name: "phoneNumber",
              country: "cm",
              required: true,
              autoFocus: true,
            }}
            // containerStyle={{
            //   Padding: "5px",
            // }}
            inputStyle={{
              width: "100%",
              height: "42px",
              borderRadius: "10px",
              outline: "none",


              backgroundColor: "#fff",
              border: "none",
            }}
            regions={["north-america", "africa", "europe"]}
            value={newPhoneNumber}
            onChange={handlePhoneInputChange}
            // onChange={props.inputChange}
            // value={props.data.phonNumber}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
          <label className="error text-danger">
            {validator.message(
              "phoneNumber",
              phoneNumber,
              "required|phoneNumber",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="imageUpload__error">
          <span className="text-danger">{error}</span>
        </div>
        {loading && <img src={loader} alt="" className="loading__image" />}
        <button type="submit" className="register__submit">
          {t("edit post")}
        </button>
        {error && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default EditPost;
